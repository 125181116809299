@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: "Montserrat", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

select {
  color: black;
  transition: color 0s 2147483647s;
}

select option {
  color: #333;
}

option[value=""][disabled] {
  display: none;
}
